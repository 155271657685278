import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { v4 as uuid } from "uuid";
import { Store } from "@ngrx/store";
import { isSuccess } from "@nll/datum/DatumEither";
import { KeycloakService } from "keycloak-angular";

import { Observable, from, merge, of, timer } from "rxjs";
import {
  delayWhen,
  filter,
  map,
  mapTo,
  retryWhen,
  switchMap,
  take,
} from "rxjs/operators";
import { selectProfile } from "src/app/core/ngrx/myqq";
import { selectKeycloakInitialized } from "src/app/core/ngrx/ui";

import { WINDOW } from "src/app/core/services/window.service";
import { environment } from "src/environments/environment";
import { UnleashService } from "./unleash.service";
import { PwaService } from "./pwa.service";
interface NewWindow extends Window {
  consentManagerConfig: any;
}
@Injectable({
  providedIn: "root",
})
export class SnippetService {
  segmentScriptAdded = false;
  refinerScriptAdded = false;
  kcInitTimeout = 5000; // ms
  surveyRetries = 10;
  surveyDelay = 250; // ms
  surveyAnalyticsError = new Error("Analytics not initialized");
  surveyFlagName = "enable-surveys";

  private _renderer: Renderer2;

  private get renderer(): Renderer2 {
    if (!this._renderer) {
      this._renderer = this.rendererFactory.createRenderer(null, null);
    }
    return this._renderer;
  }

  constructor(
    private store$: Store,
    private rendererFactory: RendererFactory2,
    private auth: KeycloakService,
    private unleash: UnleashService,
    private pwa: PwaService,
    @Inject(WINDOW) private window: Window
  ) {}

  generateSnippets(): Observable<boolean> {
    const loadSegment =
      environment.segmentConfig.track && !this.segmentScriptAdded;
    const loadRefiner =
      environment.refinerConfig?.enable &&
      !this.refinerScriptAdded &&
      environment.unleash;
    const loadConsentManager = !(this.pwa.standalone && this.pwa.isInstalled);

    // Refiner is a Segment integration, short-circuit if Segment will not be loaded
    if (!loadSegment) {
      return of(false);
    }

    const kcInitializationObservableWithTimeout = merge(
      this.store$.select(selectKeycloakInitialized),
      timer(this.kcInitTimeout).pipe(mapTo(false))
    );

    return kcInitializationObservableWithTimeout.pipe(
      filter((init) => init !== null),
      take(1), // Observable must complete
      map((initialized) => {
        if (!initialized) {
          return true;
        }
        if (loadConsentManager) {
          this.enableConsentManager();
        }
        if (loadSegment) {
          this.addSegmentSnippet();
        }
        if (loadRefiner) {
          this.addRefinerSnippet();
        }

        return true;
      })
    );
  }

  private addSegmentSnippet() {
    const analyticsScript = this.renderer.createElement("script");
    analyticsScript.type = "text/javascript";
    analyticsScript.async = true;
    analyticsScript.text = environment.segmentConfig.snippet;

    this.renderer.appendChild(this.window.document.head, analyticsScript);

    this.segmentScriptAdded = true;
  }

  private enableConsentManager() {
    const win = this.window as NewWindow;

    win.consentManagerConfig = () => ({
      ...environment.consentManagerInput,
      writeKey: environment.segmentConfig.writeKey,
    });

    const contentElement = this.renderer.createElement("div");
    contentElement.id = "consent";

    const consentManagerScript = this.renderer.createElement("script");
    consentManagerScript.type = "text/javascript";
    this.renderer.setAttribute(
      consentManagerScript,
      "src",
      environment.consentManagerUrl
    );
    this.renderer.setAttribute(consentManagerScript, "defer", "true");

    this.renderer.appendChild(this.window.document.body, contentElement);
    this.renderer.appendChild(this.window.document.head, consentManagerScript);
  }

  private async addRefinerSnippet() {
    // Wait for first successful profile load with an account id
    // Segment user id will then have been set to the account id
    // This way, refiner and segment have the same id and data can be linked
    this.store$
      .select(selectProfile)
      .pipe(
        filter(isSuccess),
        map((res) => res.value.right.profile.info.qsysAccount),
        filter((accountId) => !!accountId),
        take(1),
        switchMap(() => this.unleash.enableSurveys$.pipe(take(1))),
        switchMap(() =>
          from(this.auth.isLoggedIn()).pipe(
            map((isLoggedIn) => {
              if (this.surveyRetries < 1) {
                return false;
              }
              // Make sure analytics is fully initialized
              if (
                this.window.analytics &&
                typeof this.window.analytics.user === "function" &&
                typeof this.window.analytics.user().id === "function" &&
                typeof this.window.analytics.user().anonymousId === "function"
              ) {
                return isLoggedIn;
              }
              this.surveyRetries--;

              throw this.surveyAnalyticsError; // Throw error to retry
            }),
            retryWhen((errors) =>
              errors.pipe(delayWhen(() => timer(this.surveyDelay)))
            )
          )
        )
      )
      .subscribe((showSurvey) => {
        if (showSurvey) {
          const refinerScript = environment.refinerConfig?.snippet;
          if (!refinerScript) {
            return;
          }
          const refinerScriptElement = this.renderer.createElement("script");
          refinerScriptElement.type = "text/javascript";
          refinerScriptElement.text = refinerScript;

          this.renderer.appendChild(
            this.window.document.body,
            refinerScriptElement
          );

          const kcProfile = this.auth.getKeycloakInstance().profile;
          const segmentId =
            this.window.analytics.user().id() ??
            this.window.analytics.user().anonymousId() ??
            uuid();

          const identity = {
            id: segmentId,
            email: kcProfile.email,
            name: kcProfile.firstName + " " + kcProfile.lastName,
          };

          const refiner = (this.window as any)._refiner;
          if (refiner && typeof refiner === "function") {
            refiner("identifyUser", identity);

            this.refinerScriptAdded = true;
          }
        }
      });
  }

  // Call this function with the form id to show the survey
  public async showSurvey(formId: string) {
    const refiner = (this.window as any)._refiner;

    if (refiner && typeof refiner === "function") {
      refiner("showForm", formId);
    }
  }
}
