// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "ed88a1e8bd93aafc75ab74953aa64c4e1d780273",
  version: "1.107.0",
};
