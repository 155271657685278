import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { validate } from "uuid";

import { omit } from "src/lib/util";
import { routes } from "src/app/app-routing.module";
import { environment } from "src/environments/environment";
import { WINDOW } from "../../services/window.service";
import { PageView } from "./segment.models";
import { selectSalesPipeline } from "../ui";
import { SnippetService } from "src/app/shared/services/snippet.service";
import { PwaService } from "src/app/shared/services/pwa.service";
import { version } from "src/environments/version";
import { timer } from "rxjs";
const WAIT_FOR_CONSENT_MANAGER = 30; // seconds
const navigationName = "Navigated";
interface PathDetails {
  category: string;
  name: string;
  uuid?: string;
}

type ConsentPrefs = { customPreferences: { Analytics: boolean } };

@Injectable({
  providedIn: "root",
})
export class SegmentService {
  private routerModals: Array<string>;
  private consented = true; // consent is opt out.

  constructor(
    readonly store$: Store<any>,
    readonly segmentSnippet: SnippetService,
    readonly pwa: PwaService,
    @Inject(WINDOW) private window: Window
  ) {
    this.waitForConsentManagerAndSetConsented();

    this.enabled = environment.segmentConfig.track;

    this.routerModals = routes
      .filter((r) => r.outlet === "modal")
      .map((r) => r.path);
  }
  private enabled = false;

  readonly salesPipeline$ = this.store$.select(selectSalesPipeline);

  readonly pageView = ({ path, name, properties }: PageView) => {
    if (!this.enabled || !this.segmentSnippet.segmentScriptAdded) return;
    // For customers in sales pipeline, set category to 'sales'
    this.salesPipeline$.subscribe(({ isSalesPipeline, origination }) => {
      if (!this.window.analytics) {
        throw new Error("Segment page call failed: script not present!");
      }
      const windowPathname = this.window.location.pathname;
      const pathDetails = this.handlePath(path || windowPathname);
      const category = pathDetails.category || windowPathname;

      // If this is a 'Navigated' call from the router, then let the handlePath figure out the actual name;
      // if it's a custom-named pageview call, use that.
      let actualName =
        name === navigationName ? pathDetails.name || windowPathname : name;

      // Include the step in the name, if this is a step
      // or the status if is this is a visual event like a popup
      if (properties.step) actualName += ` - ${properties.step}`;
      else if (properties.status) actualName += ` - ${properties.status}`;

      const props = {
        ...properties,
        ...omit(pathDetails, "category", "name"),
        salesPipeline: isSalesPipeline && origination !== "myqq",
        device: this.pwa.isMobile ? this.pwa.device : "desktop",
        usingInstalled: this.pwa.standalone,
        isInstalled: this.pwa.installed,
        ...omit(version, "app"),
      };

      this.window.analytics.page(category, actualName, props, {
        integrations: { All: this.consented, Mouseflow: false }, // Don't leak PII to Mouseflow
      });
    });
  };

  readonly identify = (action) => {
    if (!this.enabled || !this.segmentSnippet.segmentScriptAdded) return;
    if (!this.window.analytics) {
      throw new Error("Segment identify call failed: script not present!");
    }

    const profile = action.value.result.profile;
    let firstName = null;
    let lastName = null;
    if (!!profile.name) {
      [firstName, lastName] = profile.name.split(" ");
    }

    this.window.analytics.identify(
      profile.info?.qsysAccount,
      {
        firstName: firstName,
        lastName: lastName,
        email: profile.userName,
        ...action.value.result.traits,
      },
      {
        integrations: { All: this.consented, Mouseflow: false }, // Don't leak PII to Mouseflow
      }
    );
  };

  readonly track = (action) => {
    if (!this.enabled || !this.segmentSnippet.segmentScriptAdded) return;
    if (!this.window.analytics) {
      throw new Error("Segment event call failed: script not present!");
    }
    this.window.analytics.track(action.type, action.value, {
      integrations: { All: this.consented, Mouseflow: false }, // Don't leak PII to Mouseflow
    });
  };

  readonly collectEmail = (email: string, promo: string) => {
    const profile = {
      userName: email,
      name: null,
    };
    const action = {
      type: "Notify Email",
      value: { result: { profile }, promo: promo },
    };

    this.identify(action);
    this.track(action);
  };

  /**
   * Helpers
   */

  handlePath(path: string): PathDetails {
    let result = <PathDetails>{};
    if (!path) {
      return result;
    }

    const pathSansQueryParams = path.split("?")[0];
    const pathSansFragmentId = pathSansQueryParams.split("#")[0];
    const isHome = pathSansFragmentId === "/";
    const paths = pathSansFragmentId.split("/");

    // For top-route-level components, name and category are the same
    result = {
      category: isHome ? "home" : paths[1],
      name: isHome ? "home" : paths[1],
    };

    // Handle special last-level URL segments
    const finalPath = paths[paths.length - 1];

    if (this.routerModals.includes(finalPath)) {
      // Modals
      result.name = `${finalPath} modal`;
    } else if (validate(finalPath)) {
      // Vehicle detail
      result.uuid = paths[2];
      result.name += " detail";
    } else if (finalPath === "edit") {
      // Vehicle edit
      result.uuid = paths[2];
      result.name += " edit";
    }

    return result;
  }

  waitForConsentManagerAndSetConsented() {
    let i = 0;
    const loaderSub = timer(0, 1).subscribe(() => {
      if (this.pwa.standalone && this.pwa.isInstalled) {
        this.consented = false;
        loaderSub.unsubscribe();
      } else if (
        typeof this.window?.consentManager?.preferences?.loadPreferences ===
          "function" &&
        typeof this.window?.consentManager?.preferences?.onPreferencesSaved ===
          "function"
      ) {
        this.window.consentManager?.preferences?.onPreferencesSaved?.(
          (prefs: ConsentPrefs) => {
            this.consented = prefs?.customPreferences?.Analytics;
          }
        );
        const consentPrefs: ConsentPrefs = this.window.consentManager?.preferences?.loadPreferences?.();
        this.consented =
          consentPrefs?.customPreferences?.Analytics ?? this.consented;

        loaderSub.unsubscribe();
      } else if (i > WAIT_FOR_CONSENT_MANAGER * 1000) {
        console.warn("Consent Manager not found");
        loaderSub.unsubscribe();
      }
      i++;
    });
  }
}
